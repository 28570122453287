import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideStore } from '@ngxs/store';
import { withNgxsReduxDevtoolsPlugin } from '@ngxs/devtools-plugin';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Loader } from '@googlemaps/js-api-loader';
import { HeaderInterceptor } from '@core/interceptors/header.interceptor';
import { ApiInterceptor } from '@core/interceptors/api.interceptor';
import { provideInsights } from '@core/services/common/insights.service';
import { ErrorInterceptor, UnauthorizedInterceptor } from 'ngx-q360-lib';
import { provideAnimations } from '@angular/platform-browser/animations';
import { appState } from '@app/store';
import { Configuration } from '@environments/configuration';
import { provideRouter, withComponentInputBinding, withRouterConfig } from '@angular/router';
import { routes } from '@app/app.routes';
import { AuthConfig, OAuthModuleConfig, OAuthStorage, provideOAuthClient } from 'angular-oauth2-oidc';
import { authAppInitializerFactory } from '@app/auth/auth-app-initializer.factory';
import { AuthService } from '@app/auth/auth.service';
import { authConfig } from '@app/auth/auth-config';
import { authModuleConfig } from '@app/auth/auth-module-config';
import { AuthGuardWithForcedLogin } from '@app/auth/auth-guard-with-forced-login.service';
import { AuthGuard } from '@app/auth/auth-guard.service';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { CDK_DRAG_CONFIG } from '@angular/cdk/drag-drop';
import { environment } from '@environments/environment';

const storageFactory = (): OAuthStorage => {
  return sessionStorage;
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideOAuthClient(),
    AuthService,
    AuthGuard,
    AuthGuardWithForcedLogin,
    { provide: APP_INITIALIZER, useFactory: authAppInitializerFactory, deps: [AuthService], multi: true },
    { provide: AuthConfig, useValue: authConfig },
    { provide: OAuthModuleConfig, useValue: authModuleConfig },
    { provide: OAuthStorage, useFactory: storageFactory },
    importProvidersFrom(NgApexchartsModule, NgxGpAutocompleteModule),
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: environment.googleMapsKey,
        libraries: ['places'],
      }),
    },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    { provide: CDK_DRAG_CONFIG, useValue: { zIndex: 99999 } },
    provideRouter(routes, withComponentInputBinding(), withRouterConfig({ paramsInheritanceStrategy: 'always' })),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideAngularSvgIcon(),
    provideStore(appState, { developmentMode: !Configuration.isProduction }),
    withNgxsReduxDevtoolsPlugin({ disabled: Configuration.isProduction }),
    provideInsights({ enabled: !Configuration.isLocal }),
  ],
};
