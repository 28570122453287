import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { ProjectSelectors } from '@app/store/project/projects.selectors';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestToForward = req;
    const projectId = this.store.selectSnapshot(ProjectSelectors.selectedProjectId);
    if (projectId) {
      requestToForward = req.clone({ setHeaders: { 'x-project-id': projectId } });
    }
    return next.handle(requestToForward);
  }
}
