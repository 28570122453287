import { Component, Renderer2 } from '@angular/core';
import { SplashScreenService } from '@core/services/common/splash-screen.service';
import { Store } from '@ngxs/store';
import { UpdateLocalStorage } from '@app/store/project/project.actions';
import { AlertComponent } from './shared/notifications-shared/components/alert/alert.component';
import { RouterOutlet } from '@angular/router';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, AlertComponent],
})
export class AppComponent {
  constructor(
    private splashScreenService: SplashScreenService,
    private store: Store,
    private renderer: Renderer2,
  ) {
    this.store.dispatch(new UpdateLocalStorage({ ...localStorage }));

    window.addEventListener('storage', () => {
      if (localStorage) {
        this.store.dispatch(new UpdateLocalStorage({ ...localStorage }));
      }
    });

    this.addGoogleMapsInlineScript();
    this.loadScript('https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js');
  }

  public loadScript(src: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = src;
    body.appendChild(script);
  }

  addGoogleMapsInlineScript(): void {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';

    script.text = `
      (g => {
        var h, a, k, p = "The Google Maps JavaScript API", c = "google", l = "importLibrary", q = "__ib__", m = document, b = window;
        b = b[c] || (b[c] = {});
        var d = b.maps || (b.maps = {}), r = new Set, e = new URLSearchParams, u = () => h || (h = new Promise(async (f, n) => {
          await (a = m.createElement("script"));
          e.set("libraries", [...r] + "");
          for (k in g) e.set(k.replace(/[A-Z]/g, t => "_" + t[0].toLowerCase()), g[k]);
          e.set("callback", c + ".maps." + q);
          a.src = "https://maps." + c + "apis.com/maps/api/js?" + e;
          d[q] = f;
          a.onerror = () => h = n(Error(p + " could not load."));
          a.nonce = m.querySelector("script[nonce]")?.nonce || "";
          m.head.append(a);
        }));
        d[l] ? console.warn(p + " only loads once. Ignoring:", g) : d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n));
      })({
        v: "weekly",
        key: "${environment.googleMapsKey}"
      });
    `;

    this.renderer.appendChild(document.body, script);
  }
}
