// Based on https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards
import { Configuration } from '@environments/configuration';
import { environment } from '@environments/environment';
import { AuthConfig } from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {
  issuer: environment.identityUrl,
  skipIssuerCheck: Configuration.isLocal,
  clientId: environment.client_id,
  responseType: 'code',
  redirectUri: window.location.origin + '/callback',
  postLogoutRedirectUri: window.location.origin,
  silentRefreshRedirectUri: window.location.origin + '/silent-renew.html',
  scope: 'openid profile api', // Ask offline_access to support refresh token refreshes
  useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
  sessionChecksEnabled: true,
  showDebugInformation: false, // Also requires enabling "Verbose" level in devtools
  clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
};
