import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestToForward = req;
    if (req.headers.get('content-type') === 'application/json' && !req.url.includes(environment.apiUrl)) {
      requestToForward = req.clone({ url: environment.apiUrl + req.url });
    }
    return next.handle(requestToForward);
  }
}
