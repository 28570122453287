import { UserState } from './user/user.state';
import { OrganisationState } from './organisation/organisation.state';
import { ProjectState } from './project/project.state';
import { NotificationState } from './notification/notification.state';
import { ProjectDecisionState } from '@project/store/project-decisions/project-decisions.state';
import { SelectedProjectState } from '@project/store/selected-project/selected-project.state';
import { ProjectTeamState } from '@project/store/team/project-team.state';
import { ProjectImageAnalyticsState } from '@project/store/image-analytics/project-image-analytics.state';

export const appState = [
  UserState,
  OrganisationState,
  ProjectState,
  NotificationState,
  ProjectDecisionState,
  SelectedProjectState,
  ProjectTeamState,
  ProjectImageAnalyticsState,
];

export type StoreState = typeof appState;
