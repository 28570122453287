@for (alert of alerts; track alert) {
  <div class="alert-container" [class]="cssClass(alert)" [@fadeInRightOnEnter] [@fadeOutOnLeave]>
    @if (alert.type === 4 && alert.notification) {
      <div>
        <app-notification
          class="alert-notification flex"
          [notification]="alert.notification"
          [popupMode]="true"
          (closeNotifications)="removeAlert(alert)"
        />
      </div>
    } @else {
      <svg-icon
        class="alert alert-dismissable svg-24 mr-8"
        [ngClass]="svgIcon(alert).color"
        [src]="svgIcon(alert).icon | iconPath: 'framed'"
      />
      <span [innerHTML]="alert.message"></span>
    }
  </div>
}
